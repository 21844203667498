/**
 * @name App
 * @description First most component that always gets loaded while kickstart of the app
 * @author Vinay
 */
import "normalize.css";
import React, { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import theme from "src/helpers/theme";
import { ThemeProvider } from "styled-components";
import "swiper/css/swiper.css";
import "./App.css";
import Spinner from "./components/Spinner";
import Socket from "./helpers/Socket";
import Screens from "./screens";
import store, { persistor } from "./store";

const App = () => {
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  useEffect(() => {
    Socket.init(() => {
      setIsSocketConnected(true);
    });
  }, []);

  return !isSocketConnected ? (
    <Spinner fullHeight />
  ) : (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Screens />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
