import Types from "../types/app";

//these are action creators, they should only create actions, no side effects

export const setActiveLanguage = code => {
  return {
    type: Types.SET_ACTIVE_LANGUAGE,
    payload: code,
  };
};

export const setActiveLanguageFromDevice = code => {
  return {
    type: Types.SET_LANGUAGE_FROM_DEVICE,
    payload: code,
  };
};

//not used and just adding confusion
// export const initialSetLanguage = () => async (dispatch: Dispatch) => {
//   try {
//     // get language from device
//     const locale = navigator.language || (navigator as any).userLanguage;
//     // remove country code from locale
//     let lang = locale.substring(0, locale.indexOf("-"));
//     // if language is not identified then set it to english
//     if (!lang) {
//       lang = "en";
//     }

//     // set language in redux
//     dispatch({ type: Types.SET_LANGUAGE_FROM_DEVICE, payload: lang });

//     // gather locationId and supported languages
//     const state = store.getState();
//     const locationId = state.location["activeLocation"];

//     // capture set language event
//     captureSetLanguage({ language: lang, requestType: "SET_LANGUAGE_FROM_DEVICE" });
//   } catch (error) {}
// };

//not used and just adding confusion
// export const setLanguage = (language: string, locationId: string) => async (
//   dispatch: Dispatch
// ) => {
//   try {
//     dispatch({ type: Types.SET_LANGUAGE, payload: { language, locationId } });
//     captureSetLanguage({ language, requestType: "SET_LANGUAGE" });
//   } catch (error) {}
// };

//not used and just adding confusion
// export const getLanguageForLocation = (locationId: string) => {
//   const state = store.getState();
//   const supportedLanguages =
//     idx(state, x => x.location.locations[locationId].data.supportedLanguages) ||
//     [];
//   const deviceLanguage = idx(state, x => x.language.deviceLanguage);
//   const language = idx(state, x => x.language.language[locationId]);
//   if (language) {
//     // if user has already set language for this location manually
//     return language;
//   } else {
//     // if user hasn't change location language then
//     // check whether location support device language,
//     // if it support then show content in device language
//     // else show it in english
//     if (supportedLanguages.indexOf(deviceLanguage) > -1) {
//       return deviceLanguage;
//     } else {
//       return "en";
//     }
//   }
// };

export default {};
