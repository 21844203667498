import createTypes from "redux-create-action-types";
import { LanguageType } from "./common";
import { ExhibitDataType } from "./exhibit";

export interface AreaType {
  exhibitOrder?: string[];
  exhibits: ExhibitDataType[];
  locationId: string;
  name: LanguageType[];
  organizationId: string;
  status: number;
  _id: { $oid: string };
}

export default createTypes("UPDATE_MULTIPLE_AREAS");
