import constants from "src/helpers/constants";
import Types from "../types/app";

const initialState = {
  deviceLanguage: constants.DEVICE_LANGUAGE(), // set the device language here. It shouldn't change. If the user _does_ switch device languages, it will take effect on the next reload...
  activeLanguage: "", // this will be set on app load, and will be the language used throughout the app. It will be set to the device language on app load, and can be changed by the user in the settings page
};

export default (
  state = initialState,
  action: IReduxAction
): typeof initialState => {
  if (
    action.type === Types.SET_LANGUAGE_FROM_DEVICE ||
    action.type === Types.SET_ACTIVE_LANGUAGE
  ) {
    return {
      ...state,
      activeLanguage: action.payload,
    };
  }

  return state;
};
