import Request from "./request";

export const getExhibitContent = async (id: string) => {
  return Request.call({
    url: "/content/getByExhibitId",
    method: "POST",
    data: { id },
  });
};

export const getExhibitLocationsDetails = async (
  id: string,
  locationId?: string
) => {
  return Request.call({
    url: "/exhibit/get",
    method: "POST",
    data: { id, locationId },
  });
};

//NOT USED
// export const getExhibitId = async (url: string) => {
//   return Request.call({
//     url: `https://api.branch.io/v1/url?url=${url}&branch_key=${process.env.REACT_APP_BRANCH_KEY}`,
//     baseURL: "/",
//   });
// };
