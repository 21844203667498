import styled from "styled-components";
export interface Props {
  locationId: string;
}

export const BannerWrapper = styled.div<{ image: string }>`
  height: 250px;
  background-position: center center;
  background-size: cover;
  position: relative;
  background-image: ${props => "url(" + props.image + ")"};
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  padding: 20px;
  z-index: 1;
`;

export const BannerTopIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // z-index: 1;
  height: 100%;
  width: 100%;
  justify-content: end;
`;

export const TitleLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const InnerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CompanyName = styled.span`
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: clamp(1.563rem, 1.4212rem + 0.7564vw, 1.875rem);
  line-height: normal;
  text-shadow: -0.3px -0.3px 0 #000, 0.3px -0.3px 0 #000, -0.3px 0.3px 0 #000,
    0.3px 0.3px 0 #000;
`;
export const Title = styled.div`
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 00;
  line-height: normal;
  font-size: clamp(1.125rem, 0.9545rem + 0.9091vw, 1.5rem);
  text-shadow: -0.3px -0.3px 0 #000, 0.3px -0.3px 0 #000, -0.3px 0.3px 0 #000,
    0.3px 0.3px 0 #000;
`;

export const TitleLogo = styled.div<{ logo: string }>`
  height: 50px;
  width: 50px;
  background-position: center center;
  background-size: cover;
  position: relative;
  margin-right: 15px;
  background-image: ${props => "url(" + props.logo + ")"};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;
