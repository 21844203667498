/**
 * @name AspectRatio
 * @description Contains AspectRatio component which helps in maintaining the desired aspect ratio.
 * @author Vinay
 */

import React from "react";
import { InnerWrapper, OuterWrapper } from "./styled";

interface Props {
  children?: any;
  /**
   * The width divided by the height. This ratio can be passed in
   * using JavaScript division syntax. So, to get a 16:9 ratio,
   * simply pass `ratio={16/9}`.
   */
  ratio: number;
}
const AspectRatio = ({ children, ratio }: Props) => (
  <OuterWrapper ratio={ratio}>
    <InnerWrapper>{children}</InnerWrapper>
  </OuterWrapper>
);

export default AspectRatio;
