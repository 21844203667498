import styled from "styled-components";

export interface Props {}

export const Content = styled.div`
  padding: 20px 0px 20px 10px;
`;

export const Page = styled.div`
  max-width: 1000px;
  margin: 0px auto;
  background-color: white;
  min-height: 100vh;
`;
