import { device } from "src/helpers/responsive";
import styled, { css } from "styled-components";

export const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media ${device.desktop} {
    visibility: hidden;
  }
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Dot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 1.5px;
  margin: 2px 3px;
  background-color: #cdcdcd;
  display: inline-block;
  flex: 0 0 auto;
  -webkit-transition: all 500ms ease-out 0s;
  -moz-transition: all 500ms ease-out 0s;
  -o-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
  ${props =>
    props["dot-color"] &&
    css`
      background-color: ${props["dot-color"]};
    `}

  &.active {
    height: 8px !important;
    width: 8px !important;
    border-radius: 1.5px !important;
    margin: 0 4px !important;
    background-color: #777777;
    ${props =>
      props["active-color"] &&
      css`
        background-color: ${props["active-color"]};
      `}
  }

  &.small {
    height: 4px;
    width: 4px;
    border-radius: 1.5px;
    margin: 2px 3px;
  }

  &.medium {
    height: 6px;
    width: 6px;
    border-radius: 1.5px;
    margin: 1px 3px;
  }

  &.large {
    height: 8px;
    width: 8px;
    border-radius: 1.5px;
    margin: 0 4px;
  }

  &.hidden {
    visibility: hidden;
  }
`;

export const DotInner = styled.div<{ current: number; total: number }>`
  max-width: 172px;
  display: flex;
  display: inline-flex;
  overflow: hidden;
  ${Dot}:nth-of-type(1) {
    margin-left: ${props => {
      if (props.total > 10) {
        if (props.total - props.current <= 5) {
          return -(props.total - 11) * 16 + "px";
        } else if (props.current > 5) {
          return -(props.current - 5) * 16 + "px";
        }
      }
      return "4px";
    }};
  }
`;
