import idx from "idx";
import * as Api from "src/api";
import { handleLocationResponse } from "src/helpers/location";
import Types from "../types/locations";

export const getLocation = id => async dispatch => {
  try {
    const res = await Api.getLocation(id);
    if (res && res.location) {
      const { location, areas } = handleLocationResponse(res.location);
      dispatch({
        type: Types.UPDATE_LOCATION_AND_AREAS,
        payload: {
          location: {
            id: idx(location, x => x._id.$oid),
            data: location,
          },
          areas,
        },
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_LOCATION_FAILURE, payload: error.toString() });
  }
};

export const updateLocationWithoutTemporaryExhibit = (locationId: string) => {
  return {
    type: Types.UPDATE_LOCATION_WITHOUT_TEMPORARY_PAGE,
    payload: locationId,
  };
};
