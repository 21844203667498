const MobileDetect = require("mobile-detect");
const md = new MobileDetect(window.navigator.userAgent);

export enum LanguageEnum {
  en = "English",
  es = "Spanish",
  ar = "Arabic",
  fa = "Persian",
  fr = "French",
  "zh-Hans" = "Simplified Chinese",
  de = "German",
  vi = "Vietnamese",
  el = "Greek",
  it = "Italian",
  ja = "Japanese",
  ko = "Korean",
  no = "Norwegian",
  pt = "Portuguese",
  ru = "Russian",
  rw = "Kinyarwanda",
  so = "Somali",
  sw = "Swahili",
  tr = "Turkish",
}

export default {
  JWPLAYER_VIDEO_URL: id => `https://content.jwplatform.com/videos/${id}.mp4`,
  JWPLAYER_VIDEO_THUMB: id => `https://cdn.jwplayer.com/thumbs/${id}-360.jpg`,
  DEFAULT_TOPIC_ID: () => {
    let defaultExhibitId = "5a71c7cd39299114b01b6270";
    if (process.env.REACT_APP_ENV === "development") {
      defaultExhibitId = "5a71c7cd39299114b01b6270";
    } else if (process.env.REACT_APP_ENV === "staging") {
      defaultExhibitId = "5a71c7cd39299114b01b6270";
    } else if (process.env.REACT_APP_ENV === "production") {
      defaultExhibitId = "5a71c7cd39299114b01b6270";
    }
    return defaultExhibitId;
  },
  DEFAULT_SECTION_ID: () => {
    let defaultSectionId = "5a71c43239299103b31a1f1d";
    if (process.env.REACT_APP_ENV === "development") {
      defaultSectionId = "5a71c43239299103b31a1f1d";
    } else if (process.env.REACT_APP_ENV === "staging") {
      defaultSectionId = "5a71c43239299103b31a1f1d";
    } else if (process.env.REACT_APP_ENV === "production") {
      defaultSectionId = "5a71c43239299103b31a1f1d";
    }
    return defaultSectionId;
  },
  DEFAULT_MENU_ID: () => {
    let defaultMenuId = "5a70d127392991080e75ccf9";
    if (process.env.REACT_APP_ENV === "development") {
      defaultMenuId = "5a70d127392991080e75ccf9";
    } else if (process.env.REACT_APP_ENV === "staging") {
      defaultMenuId = "5a70d127392991080e75ccf9";
    } else if (process.env.REACT_APP_ENV === "production") {
      defaultMenuId = "5a70d127392991080e75ccf9";
    }
    return defaultMenuId;
  },
  DEVICE_LANGUAGE: () => {
    const locale = navigator.language || (navigator as any).userLanguage;
    let lang = locale.split("-")[0];
    if (lang === "zh") {
      lang = "zh-Hans";
    }
    return lang;
  },
  os: () => {
    if (!md.os()) {
      return navigator.platform;
    } else if (
      md
        .os()
        .toLowerCase()
        .indexOf("android") > -1
    ) {
      return "android";
    } else if (
      md
        .os()
        .toLowerCase()
        .indexOf("ios") > -1
    ) {
      return "ios";
    }
    return md.os();
  },
};
