import idx from "idx";
import { ViewsByTypes } from "src/api";
import utility from "src/helpers/utility";
import Types from "../types/app";

export const servers = {
  DEV: "https://api-liiingo.ventive.app/api/",
  STAGING: "https://api-liiingo-staging.ventive.app/api/",
  PROD: "https://api.liiingo.com/api/",
};

const initialState = {
  udid: utility.getUdid(),
  dummyEvent: "",
  connectedToInternet: true,
  visitedScreens: [],
  pushNotificationSettings: {} as {
    [locationId: string]: boolean;
  },
  currentExhibitId: "",
  currentExhibitViewsBy: "" as ViewsByTypes,
  currentSectionId: "",
  currentMenuId: "",
};

export default (
  state = initialState,
  action: IReduxAction
): typeof initialState => {
  switch (action.type) {
    case "persist/REHYDRATE": {
      const payload = action.payload;
      const appPayload = idx(payload, x => x.app) || {};
      if (appPayload) {
        return {
          ...initialState,
          ...appPayload,
          udid: utility.getUdid(),
        };
      }
      return {
        ...initialState,
        ...appPayload,
      };
    }
    case Types.DUMMY_REDUX_EVENT: {
      return state;
    }
    case Types.SET_CURRENT_EXHIBIT_ID: {
      return {
        ...state,
        currentExhibitId: idx(action, x => x.payload.id),
        currentExhibitViewsBy: idx(action, x => x.payload.viewsBy),
      };
    }
    case Types.SET_CURRENT_SECTION_ID: {
      return {
        ...state,
        currentSectionId: action.payload,
      };
    }
    case Types.SET_CURRENT_MENU_ID: {
      return {
        ...state,
        currentMenuId: action.payload,
      };
    }
    case Types.SET_IS_CONNECTED_TO_INTERNET: {
      return {
        ...state,
        connectedToInternet: action.payload,
      };
    }
    case Types.ADD_VISITED_SCREENS: {
      const { screenName, params } = action.payload || ({} as any);
      return {
        ...state,
        visitedScreens: [...state.visitedScreens, { screenName, params }],
      };
    }
    case Types.REMOVE_VISITED_SCREENS: {
      const newVisitedScreen = [...state.visitedScreens];
      newVisitedScreen.pop();
      return {
        ...state,
        visitedScreens: [...newVisitedScreen],
      };
    }
    case Types.RESET_VISITED_SCREEN: {
      return {
        ...state,
        visitedScreens: [],
      };
    }
    case Types.UPDATE_PUSH_NOTIFICATION_SETTINGS_FOR_LOCATION: {
      const { locationId, opted } = action.payload;
      return {
        ...state,
        pushNotificationSettings: {
          ...state.pushNotificationSettings,
          [locationId]: opted,
        },
      };
    }
    default:
      return state;
  }
};
