import idx from "idx";
import last from "lodash/last";
import io from "socket.io-client";
import Constants from "src/helpers/constants";
import store from "src/store";
import { IReduxState } from "src/store/reducers";

export interface ViewedContent {
  locationId?: string;
  exhibitId?: string;
  areaId?: string;
  organizationId?: string;
  openTime?: string;
  closeTime?: string;
}

class Socket {
  public socket: any;
  public viewedContent: ViewedContent[];
  public eventsCount: number;

  constructor() {
    this.eventsCount = 0;
    this.viewedContent = [];
  }

  public init(onSocketConnect) {
    const state: IReduxState = store.getState();
    const udid = idx(state, x => x.app.udid);

    this.socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: "/liiingo",
      transports: ["websocket"],
    });

    this.socket.on("connect", () => {
      if (onSocketConnect) {
        onSocketConnect();
      }
      // when socket connects, we will send device info to server
      this.socket.emit("info", {
        udid,
        os: Constants.os(),
        metadata: {
          platform: "LiiingoWebApp",
          browser: navigator.userAgent,
          viewedContent: [],
        },
      });
    });

    this.socket.on("disconnect", () => {
      this.viewedContent = [];
      this.eventsCount = 0;
    });
  }

  public onViewContent = (data: ViewedContent) => {
    try {
      this.viewedContent = this.viewedContent || [];
      const lastItem = last(this.viewedContent);

      if (lastItem) {
        // if there is last topic , then record time as closeTime
        lastItem.closeTime = new Date().toISOString();
      }

      // record new topic time as openTime
      this.viewedContent.push({
        ...data,
        openTime: new Date().toISOString(),
      });
      this.socket.emit("updateViewedContent", this.viewedContent);
    } catch (error) {
      // console.log(error);
    }
  };

  public increaseEventsCount = () => {
    this.eventsCount = this.eventsCount + 1;
    this.socket.emit("updateEventsCount", this.eventsCount);
  };
}

export default new Socket();
