/**
 * @name RelatedArticle Component
 * @description Returns a swiper list of sub pages in a category
 */

import idx from "idx";
import find from "lodash/find";
import * as React from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import utility from "src/helpers/utility";
import { IReduxState } from "src/store/reducers";
import { ExhibitDataType, LocationType } from "src/store/types";
import { AreaType } from "src/store/types/areas";
import { Topic } from "./styled";

interface Props {
  activeLanguage: string;
  exhibitId?: string;
  locationId?: string;
}

interface InternalProps {
  exhibit?: ExhibitDataType;
  location?: LocationType;
}

class RelatedArticles extends React.Component<Props & InternalProps> {
  public render() {
    const { activeLanguage, exhibit, location, exhibitId } = this.props;
    const areaId = idx(exhibit, x => x.areaId);
    const areas = idx(location, x => x.areas) || [];
    const area: AreaType =
      find(areas, { _id: { $oid: areaId } }) || ({} as AreaType);
    const exhibits: ExhibitDataType[] = idx(area, x => x.exhibits) || [];
    const defaultLanguage = location.defaultLanguage;

    const params = {
      slidesPerView: "auto",
      freeMode: true,
    };

    if (exhibits.length <= 0) {
      return null;
    }
    return (
      <Swiper {...params}>
        {exhibits.map((item, index) => {
          const names = idx(item, x => x.name);
          const exhibitName = utility.getName(
            names,
            activeLanguage,
            defaultLanguage
          );
          return (
            <Topic
              className={item._id.$oid === exhibitId ? "active" : ""}
              image={item.exhibitImageThumb || item.exhibitImage}
              key={index}
              title={exhibitName}
              exhibitId={item._id.$oid}
            />
          );
        })}
      </Swiper>
    );
  }
}

export default connect<{}, {}, Props, {}>((state: IReduxState, ownProps) => {
  const exhibit =
    idx(state, x => x.topics.exhibitDetails[ownProps.exhibitId].data) ||
    ({} as ExhibitDataType);
  const location =
    idx(state, x => x.location.locations[ownProps.locationId].data) ||
    ({} as LocationType);
  return {
    exhibit,
    location,
  };
})(RelatedArticles);
