import React, { useEffect, useRef } from "react";
import Player from "react-player";
import { useSelector } from "react-redux";
import { IReduxState } from "src/store/reducers";
import AspectRatio from "../AspectRatio/AspectRatio";

interface VideoPlayer {
  url: string;
  id: any;
  exhibitId: string;
}

const VideoPlayer = (props: VideoPlayer) => {
  const currentExhibitId = useSelector(
    (state: IReduxState) => state.app.currentExhibitId
  );
  const playerRef = useRef(null);

  useEffect(() => {
    if (currentExhibitId !== props.exhibitId) {
      playerRef?.current?.getInternalPlayer()?.pause();
    }
  }, [currentExhibitId, props.exhibitId]);

  return (
    <div className="video-wrapper" id={props.id} style={{ marginBlock: 18 }}>
      <AspectRatio ratio={16 / 9}>
        <Player
          ref={playerRef}
          playsinline={true}
          width={"100%"}
          height={"100%"}
          muted
          controls
          url={props.url}
          playing={false}
          lite={true}
        />
      </AspectRatio>
    </div>
  );
};

export default VideoPlayer;
