import idx from "idx";
import isEmpty from "lodash/isEmpty";
import * as React from "react";
import { connect } from "react-redux";
import Spinner from "src/components/Spinner";
import VideoPlayer from "src/components/VideoPlayer/VideoPlayer";
import constants from "src/helpers/constants";
import utility from "src/helpers/utility";
import { IReduxState } from "src/store/reducers";
import { ExhibitDataType } from "src/store/types";
import { LanguageType } from "src/store/types/common";
import HTMLRender from "./HTMLRender";
import Image360 from "./Image360";
import {
  ContentItem,
  HTMLContentWrapper,
  Image,
  Link,
  LinkButton,
  LinkImage,
  MediaContentWrapper,
  NoContentToView,
} from "./styled";
import { saveAnalyticsEvent } from "src/api";

interface Props {
  exhibitId?: string;
  activeLanguage: string;
  defaultLanguage: string;
  isVisible: boolean;
  updateAutoHeight: Function;
  locationId: string;
}

interface InternalProps {
  exhibit?: ExhibitDataType;
}

const Content: React.FC<Props & InternalProps> = props => {
  const { activeLanguage, defaultLanguage, exhibit, exhibitId } = props;

  const contentArr = idx(exhibit, x => x.content) || [];

  utility.getName(
    idx(exhibit, x => x.name),
    activeLanguage,
    defaultLanguage
  );

  const renderItem = (item, index) => {
    const i18nContent: LanguageType[] = item.languages;
    const langContent = utility.getLanguageData(
      i18nContent,
      activeLanguage,
      defaultLanguage
    );
    if (item.type === "text") {
      return (
        <HTMLContentWrapper key={index}>
          <HTMLRender content={langContent.content} />
        </HTMLContentWrapper>
      );
    } else if (item.type === "image360") {
      const fileUrl = langContent.fileUrl;
      return (
        <MediaContentWrapper key={index}>
          <Image360 url={fileUrl} />
        </MediaContentWrapper>
      );
    } else if (item.type === "image") {
      const fileUrl = langContent.fileUrl;
      const displayURL = langContent.fileUrlThumb || fileUrl;
      return (
        <MediaContentWrapper key={index}>
          <Image src={displayURL} alt={langContent.description} />
        </MediaContentWrapper>
      );
    } else if (item.type === "webview") {
      const fileUrl = langContent.fileUrl;
      return fileUrl ? (

        <HTMLContentWrapper key={index} style={{ textAlign: "center" }}>
          <Link href={langContent.url}
            onClick={() => {
              saveAnalyticsEvent("Button_Analytics", { id: item._id.$oid });
            }}
          >

            <LinkImage src={fileUrl} alt={langContent.description} />
          </Link>
        </HTMLContentWrapper>
      ) : (
        <HTMLContentWrapper key={index} style={{ textAlign: "center" }}>
          <LinkButton
            onClick={() => {
              window.open(langContent.url, "_self");
              saveAnalyticsEvent("Button_Analytics", { id: item._id.$oid });
            }}
          >
            {langContent.title}
          </LinkButton>
        </HTMLContentWrapper>
      );
    } else if (
      item.type === "video" &&
      langContent.fileName &&
      langContent.fileName.trim().length > 0
    ) {
      const url = constants.JWPLAYER_VIDEO_URL(langContent.content);
      return (
        <VideoPlayer url={url} id={index} key={index} exhibitId={exhibitId} />
      );
    }
    return null;
  };

  return (
    <div id={`exhibit_${exhibitId}`} style={{ overflow: "hidden" }}>
      {isEmpty(exhibit) ? (
        <Spinner />
      ) : (
        <>
          {contentArr.length > 0 ? (
            contentArr.map((item, index) => {
              return (
                <ContentItem key={index}>{renderItem(item, index)}</ContentItem>
              );
            })
          ) : (
            <NoContentToView>No Content To View</NoContentToView>
          )}
        </>
      )}
    </div>
  );
};

export default connect<{}, {}, Props, {}>((state: IReduxState, ownProps) => {
  const exhibit =
    idx(state, x => x.topics.exhibitDetails[ownProps.exhibitId].data) ||
    ({} as ExhibitDataType);
  return {
    exhibit,
  };
})(Content);
