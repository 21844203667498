import { Middleware, configureStore } from "@reduxjs/toolkit"; //configure store is the current way to create a store with redux toolkit (8/23)
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import { captureSetLanguage } from "src/api";
import rootReducer from "./reducers";
import Types from "./types/app";

// configure persisted store
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loading", "app", "language"], // dont persist langauge to ensure we're always using the device language on app load
};

// create and empty middleware to use in production
const emptyMiddleWare = () => next => action => {
  const result = next(action);
  return result;
};

let loggerMiddleware: Middleware = emptyMiddleWare;

// if in dev, use logger middleware in place of empty middleware
if (process.env.NODE_ENV === "development") {
  loggerMiddleware = logger;
}

// create custom middleware to capture events and do things outside of the state tree
// this will keep reducers clean (no side effects) (not switching everything over to this architecture yet. doing it for my own sanity -- for the task at hand)
let LiiingoMiddleware: Middleware = () => next => action => {
  //look at us, always breaking the rules. We'll call it ProperCaseCamelCase
  let result = next(action);

  // handle different actions
  if (
    action.type === Types.SET_ACTIVE_LANGUAGE ||
    action.type === Types.SET_LANGUAGE_FROM_DEVICE
  ) {
    // call api analytics endpoint, log current active and device language
    captureSetLanguage(action.type);
  }

  return result;
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false, // turing off immutable check. We're using actions wrong and dont have time to refactor everything
      serializableCheck: {
        // so yeah, this is a thing. redux toolkit is trying to help us out by making sure we're not mutating state in reducers or storing unserializable data. but it's rules are opinionated and not de facto. so we have to tell it to ignore certain actions by other libraries like redux-persist. this is the list of actions it will ignore for the serilization check.
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .prepend(LiiingoMiddleware)
      .concat(loggerMiddleware),
});

const persistor = persistStore(store);
// persistor.purge();
// persistor.flush();
export { persistor, store };
export default store;
