import Types, { LocationType } from "../types/locations";

const initialState = {
  locations: {} as {
    [locationId: string]: {
      data: LocationType;
    };
  },
};

export default (
  state = initialState,
  action: IReduxAction
): typeof initialState => {
  switch (action.type) {
    case Types.UPDATE_LOCATION: {
      const { id, data } = action.payload;
      if (id) {
        return {
          ...state,
          locations: {
            ...state.locations,
            [id]: {
              data,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case Types.UPDATE_LOCATION_AND_AREAS: {
      const { location = {} } = action.payload;
      const { id, data } = location;
      if (id) {
        return {
          ...state,
          locations: {
            ...state.locations,
            [id]: {
              data,
            },
          },
        };
      }
      return {
        ...state,
      };
    }
    case Types.REMOVE_LOCATION: {
      const locations = {};
      const sLocations = JSON.parse(JSON.stringify(state.locations));
      const allKeys = Object.keys(sLocations);
      allKeys.forEach(value => {
        if (value !== action.payload.locationId) {
          locations[value] = {
            data: sLocations[value].data,
          };
        }
      });
      return {
        ...state,
        locations,
      };
    }

    case Types.UPDATE_EXHIBITS_IN_LOCATION: {
      const { location, id } = action.payload;
      if (id) {
        return {
          ...state,
          locations: {
            ...state.locations,
            [id]: location,
          },
        };
      }
      return {
        ...state,
      };
    }

    case Types.UPDATE_LOCATION_WITHOUT_TEMPORARY_PAGE: {
      const locationId = action.payload;
      const location = state.locations[locationId];
      const areas = location.data.areas;
      const updatedArea = areas.map(item => {
        return {
          ...item,
          exhibits: item.exhibits.filter(item => item.status !== 4),
        };
      });
      return {
        ...state,
        locations: {
          ...state.locations,
          [locationId]: {
            data: {
              ...state.locations[locationId].data,
              areas: updatedArea,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
