import { combineReducers } from "redux";
import AppReducer from "./app";
import AreasReducer from "./areas";
import TopicsReducer from "./exhibit";
import ExploredReducer from "./explored";
import LanguageReducer from "./language";
import LocationReducer from "./location";

const reducers = {
  app: AppReducer,
  location: LocationReducer,
  topics: TopicsReducer,
  areas: AreasReducer,
  explored: ExploredReducer,
  language: LanguageReducer,
};

const combinedReducer = combineReducers(reducers);

export interface IReduxState {
  app: ReturnType<typeof AppReducer>;
  location: ReturnType<typeof LocationReducer>;
  topics: ReturnType<typeof TopicsReducer>;
  areas: ReturnType<typeof AreasReducer>;
  explored: ReturnType<typeof ExploredReducer>;
  language: ReturnType<typeof LanguageReducer>;
}

export default combinedReducer;
