import idx from "idx";
import isEmpty from "lodash/isEmpty";
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import Banner from "src/components/Banner";
import Page from "src/components/Page";
import SectionListItem from "src/components/SectionListItem";
import Spinner from "src/components/Spinner";
import constants from "src/helpers/constants";
import useDeepCompareEffect from "src/helpers/useDeepCompareEffect";
import * as AppActions from "src/store/actions/app";
import { IReduxState } from "src/store/reducers";
import { LocationType } from "src/store/types";
import { AreaType } from "src/store/types/areas";
import { Content } from "./styled";

type Props = IScreenProps & {
  getLocation?: Function;
  activeLanguage: string;
  location?: LocationType;
  locationId?: string;
  setCurrentMenuId?: Function;
};

const Location: React.FC<Props> = props => {
  const { activeLanguage, location, locationId, setCurrentMenuId } = props;
  const id = locationId;
  const areas = idx(location, x => x.areas) || [];

  React.useEffect(() => {
    setCurrentMenuId(id);
  }, [id, areas]); // eslint-disable-line

  useDeepCompareEffect(() => {
    const firstExhibitId = idx(location, x => x.areas[0].exhibits[0]._id.$oid);
    const linkData = { data: { exhibit_id: firstExhibitId } };
    // if branch banner data is not set then
    if (!window.isAppBannerDataSet) {
      setTimeout(() => {
        window.branch.closeJourney();
        setTimeout(() => {
          window.branch.setBranchViewData(linkData);
          window.branch.track("pageview");
          // this is used to check in other page whether to update branch banner data or not
          window.isAppBannerDataSet = true;
        }, 1000);
      }, 1000);
    }
  }, []);

  if (isEmpty(location)) {
    return <Spinner fullHeight />;
  }

  return (
    <Page>
      <Banner locationId={idx(location, x => x._id.$oid)} />
      <Content>
        {areas.map((area: AreaType) => (
          <SectionListItem
            activeLanguage={activeLanguage}
            defaultLanguage={location.defaultLanguage}
            area={area}
            key={area._id.$oid}
          />
        ))}
      </Content>
    </Page>
  );
};

export default compose<Props, Props>(
  connect(
    (state: IReduxState, ownProps: Props) => {
      const idFromUrl = idx(ownProps.match as any, x => x.params.id);
      const id = idx(state, x => x.app.currentMenuId);
      const activeLanguage = idx(state, x => x.language.activeLanguage);

      let locationId = "";

      if (idFromUrl) {
        locationId = idFromUrl;
      } else if (id) {
        locationId = id;
      } else {
        locationId = constants.DEFAULT_MENU_ID();
      }

      const location =
        idx(state, x => x.location.locations[locationId].data) ||
        ({} as LocationType);
      return {
        location,
        locationId,
        activeLanguage,
      };
    },
    {
      setCurrentMenuId: AppActions.setCurrentMenuId,
    }
  )
)(Location);
