import * as React from "react";
import { useDispatch } from "react-redux";
import { ViewsByTypes } from "src/api";
import defaultImage from "src/assets/images/default-topic.png";
import urls from "src/helpers/urls";
import { removeTemporaryTopicsFromSecretExhibits } from "src/store/actions";
import { Card, Link, Props, Title } from "./styled";

const TopicCard: React.FC<Props> = props => {
  const { image, title, exhibitId, onClick } = props;
  const dispatch = useDispatch();
  const handleClickOnTopicCard = () => {
    dispatch(removeTemporaryTopicsFromSecretExhibits());
  };
  return (
    <Link
      className={props.className}
      to={urls.exhibit(exhibitId, ViewsByTypes.menu)}
      onClick={() => handleClickOnTopicCard()}
    >
      <Card image={image || defaultImage} onClick={onClick}>
        <Title>{title}</Title>
      </Card>
    </Link>
  );
};

export default TopicCard;
