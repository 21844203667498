import * as React from "react";
import spinner from "src/assets/images/spinner.gif";
import { Props, SpinnerContainer } from "./styled";

const Spinner: React.FC<Props> = props => {
  return (
    <SpinnerContainer {...props}>
      <img src={spinner} alt="Loading" />
    </SpinnerContainer>
  );
};

export default Spinner;
