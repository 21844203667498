import styled from "styled-components";
import TopicCard from "../TopicCard";

export const SectionTitle = styled.div`
  color: ${props => props.theme.primary};
  font-size: 20px;
  font-weight: 500;
  padding: 0px 10px 10px;
`;

export const Topic = styled(TopicCard)`
  width: 150px;
  margin-right: 20px;
  margin-left: 10px;
  margin-block: 10px;
`;

export const SeeAll = styled.a`
  color: ${props => props.theme.primary};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 10px;
  cursor: pointer;
  text-decoration: none;
`;
