import createTypes from "redux-create-action-types";
import { LanguageType } from "./common";

export interface ExhibitDataType {
  _id: { $oid: string };
  exhibitImage: string;
  exhibitImageThumb: string;
  areaId: string;
  locationId: string;
  content: any[];
  createdDate: any;
  enableSharing: false;
  modifiedDate: {
    $date: {
      $numberLong: string;
    };
  };
  name: LanguageType[];
  organizationId: string;
  qrPath: string;
  slideshowSettings: any;
  status: number;
}

export default createTypes(
  "FINDING_EXHIBIT_ID",
  "GET_EXHIBIT_REQUEST",
  "GET_EXHIBIT_SUCCESS",
  "GET_EXHIBIT_ERROR",
  "SET_WHITE_LABELING",
  "SWIPED_EXHIBIT_LOADING_REQUEST",
  "SWIPED_EXHIBIT_LOADING_COMPLETE",
  "UPDATE_CACHED_EXHIBIT_DATA"
);
