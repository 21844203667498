import idx from "idx";
import isEmpty from "lodash/isEmpty";
import * as React from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import Banner from "src/components/Banner";
import Page from "src/components/Page";
import Spinner from "src/components/Spinner";
import Tabs from "src/components/Tabs";

import constants from "src/helpers/constants";
// import urls from "src/helpers/urls";
import utility from "src/helpers/utility";
import * as AppActions from "src/store/actions/app";
import * as AreaActions from "src/store/actions/area";
import { IReduxState } from "src/store/reducers";
import { AreaType } from "src/store/types/areas";
import { SwipePageContentWrapper } from "../Exhibit/styled";
import { TopicList, TopicWrapper } from "./styled";

type Props = IScreenProps & {
  activeLanguage: string;
  defaultLanguage: string;
  getArea?: Function;
  areaId?: string;
  area?: AreaType;
  areas: AreaType[];
  locationId?: string;
  setCurrentSectionId?: Function;
};

class Area extends React.Component<Props> {
  public sliderPage;

  public state = {
    currentTab: 0,
    currentPage: 0,
  };

  public componentDidMount() {
    const {
      area,
      areas,
      getArea,
      areaId,
      setCurrentSectionId,
      // match,
    } = this.props;
    // const urlContainsId = idx(match as any, x => x.params.id);

    let selectedAreaIndex = areas.findIndex(
      areaObj => areaObj._id.$oid === area._id.$oid
    );
    selectedAreaIndex = selectedAreaIndex <= -1 ? 0 : selectedAreaIndex;

    this.setState({
      currentPage: selectedAreaIndex,
      currentTab: selectedAreaIndex,
    });
    if (this.sliderPage) {
      this.sliderPage.slideTo(selectedAreaIndex);
    }

    getArea(areaId);
    this.updateBranchData();
    setCurrentSectionId(areaId);
  }

  public updateBranchData = () => {
    const { area } = this.props;
    // close journey and open it again with new first exhibit id
    // so when user click on Get/Open button , it will open first exhibit in the app
    const firstExhibitId = idx(area, x => x.exhibits[0]._id.$oid);
    const linkData = {
      data: {
        exhibit_id: firstExhibitId,
      },
    };
    // if branch banner data is not set then
    if (!window.isAppBannerDataSet) {
      setTimeout(() => {
        window.branch.closeJourney();
        setTimeout(() => {
          window.branch.setBranchViewData(linkData);
          window.branch.track("pageview");
          // this is used to check in other page whether to update branch banner data or not
          window.isAppBannerDataSet = true;
        }, 1000);
      }, 1000);
    }
  };

  public changeHandler = nextSlide => {
    this.setState({ currentTab: nextSlide, currentPage: nextSlide });
  };

  public render() {
    const {
      activeLanguage,
      defaultLanguage,
      locationId,
      areas,
      area,
    } = this.props;
    const { currentTab, currentPage } = this.state;

    if (isEmpty(area) || !locationId) {
      return <Spinner fullHeight />;
    }

    const areaTabs = areas.map((ar, i) => {
      const areaName = utility.getName(
        ar.name,
        activeLanguage,
        defaultLanguage
      );
      const areaId = idx(area, x => x._id.$oid);
      return {
        label: areaName,
        key: areaId,
      };
    });

    const params = {
      slidesPerView: 1,
      autoHeight: true,
      activeSlideKey: "" + currentPage,
      on: {
        slideChange: () => {
          const nextSlideIndex = idx(this.sliderPage, x => x.activeIndex) || 0;
          this.changeHandler(nextSlideIndex);
        },
      },
    };

    return (
      <Page>
        <Banner locationId={locationId} />
        <Tabs
          isDropDownDisabled={true}
          activeLanguage={activeLanguage}
          index={currentTab}
          onChange={(c, n) => {
            if (this.sliderPage) {
              this.sliderPage.slideTo(n);
            }
          }}
          items={areaTabs}
        />
        <SwipePageContentWrapper>
          <Swiper {...params} getSwiper={ref => (this.sliderPage = ref)}>
            {areas.map((ar, index) => (
              <TopicList key={index}>
                {(ar.exhibits || []).map(exhibit => {
                  const exhibitName = utility.getName(
                    exhibit.name,
                    activeLanguage,
                    defaultLanguage
                  );
                  return (
                    <TopicWrapper
                      image={exhibit.exhibitImageThumb || exhibit.exhibitImage}
                      key={exhibit._id.$oid}
                      title={exhibitName}
                      exhibitId={exhibit._id.$oid}
                    />
                  );
                })}
              </TopicList>
            ))}
          </Swiper>
        </SwipePageContentWrapper>
      </Page>
    );
  }
}

export default connect<Props>(
  (state: IReduxState, ownProps: Props) => {
    const idFromUrl = idx(ownProps.match as any, x => x.params.id);
    const id = idx(state, x => x.app.currentSectionId);

    let areaId = "";

    if (idFromUrl) {
      // if we have area id in url , then take it
      areaId = idFromUrl;
    } else if (id) {
      // if we have area id set in redux , then take it
      areaId = id;
    } else {
      // else take default id
      areaId = constants.DEFAULT_SECTION_ID();
    }

    const area =
      idx(state, x => x.areas.areas[areaId].data) || ({} as AreaType);
    const locationId = area.locationId;
    const areas =
      idx(state, x => x.location.locations[locationId].data.areas) ||
      ([] as AreaType[]);
    const activeLanguage = idx(state, x => x.language.activeLanguage);
    const defaultLanguage =
      state.location.locations[locationId].data.defaultLanguage;
    return {
      activeLanguage,
      defaultLanguage,
      areaId,
      area,
      areas,
      locationId,
    };
  },
  {
    getArea: AreaActions.getArea,
    setCurrentSectionId: AppActions.setCurrentSectionId,
  }
)(Area);
