import * as React from "react";
import { HTMLNode } from "./styled";

interface Props {
  content: string;
}

const HTMLRender: React.FC<Props> = props => {
  const { content } = props;
  React.useEffect(() => {
    const allCustomLinks = document.querySelectorAll("[data-topicval]");
    if (allCustomLinks) {
      for (let index = 0; index < allCustomLinks.length; index++) {
        const element = allCustomLinks[index];
        const topicId = element.getAttribute("data-topicval");
        const url = `${window.location.origin}/topic/${topicId}`;
        element.setAttribute("href", url);
      }
    }
  }, []);

  return <HTMLNode dangerouslySetInnerHTML={{ __html: content }} />;
};

export default HTMLRender;
