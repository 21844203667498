import idx from "idx";
import * as React from "react";
import { ArrowRight } from "react-feather";
import Swiper from "react-id-swiper";
import { ViewsByTypes } from "src/api";
import urls from "src/helpers/urls";
import utility from "src/helpers/utility";
import { AreaType } from "src/store/types/areas";
import { SectionTitle, SeeAll, Topic } from "./styled";

interface Props {
  activeLanguage: string;
  defaultLanguage: string;
  area: AreaType;
}

const SectionListItem: React.FC<Props> = props => {
  const { activeLanguage, defaultLanguage, area } = props;
  const exhibits = idx(area, x => x.exhibits) || [];
  const name = utility.getName(
    idx(area, x => x.name),
    activeLanguage,
    defaultLanguage
  );
  const params = {
    slidesPerView: "auto",
    freeMode: true,
  };

  return (
    exhibits.length ?
      <React.Fragment key={area._id.$oid}>
        <SectionTitle>{name}</SectionTitle>
        <Swiper {...params}>
          {exhibits.map((exhibit, index) => {
            const names = idx(exhibit, x => x.name);
            const exhibitName = utility.getName(
              names,
              activeLanguage,
              defaultLanguage
            );
            return (
              <Topic
                image={exhibit.exhibitImageThumb || exhibit.exhibitImage}
                key={index}
                title={exhibitName}
                exhibitId={exhibit._id.$oid}
              />
            );
          })}
        </Swiper>
        <div>
          <SeeAll
            href={urls.exhibit(
              idx(area, x => x.exhibits[0]._id.$oid),
              ViewsByTypes.menu
            )}>
            See All
            <ArrowRight />
          </SeeAll>
        </div>
      </React.Fragment> : <></>
  );
};

export default SectionListItem;
