import styled from "styled-components";

export interface Props {
  fullHeight?: boolean;
}

export const SpinnerContainer = styled.div<Props>`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  height: ${props => (props.fullHeight ? "100vh" : "auto")};
  background-color: #f4f4f4;
`;
