import { device } from "src/helpers/responsive";
import styled from "styled-components";
import TopicCard from "../TopicCard";
import { Card } from "../TopicCard/styled";

export const TabWrapper = styled.div`
  opacity: 1;
  background: ${props => props.theme.tabBackgroundColor};
  .slick-slide {
    div:focus {
      outline: none;
    }
  }
  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MegaMenu = styled.div`
  background-color: yellow;
  width: 100%;
  max-width: 960px;
  height: auto;
  z-index: 99;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  @media ${device.desktopAsMax} {
    display: none;
  }
`;
export const MegaMenuInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px;
  height: auto;
`;

export const Tab = styled.span<{ isSelected: boolean }>`
  padding: 10px 20px;
  text-align: center;
  color: ${props => props.theme.primary};
  border-bottom: ${props =>
    props.isSelected && `3px solid ${props.theme.primary}`};
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: normal;
  font-size: clamp(0.75rem, 0.625rem + 0.625vw, 1rem);
  cursor: pointer;
  height: auto;
  &:hover {
    opacity: 0.7;
  }
  &:hover ${MegaMenu} {
    display: block;
  }
`;

export const SwiperOutWrapper = styled.span`
  backdrop-filter: drop-shadow(4px 4px 10px blue);
  @media ${device.desktop} {
    width: 93%;
    height: auto;
  }
`;

export const TabArrowWrapper = styled.span`
  width: 3%;
  padding-left: 5px;
  display: none;

  &:active {
    opacity: 0.6;
  }
  @media ${device.desktop} {
    display: block;
  }
`;

export const MainTabMenuWrapper = styled.div<{ isFixed: boolean }>`
  position: ${props => (props.isFixed ? "fixed" : "relative")};
  top: ${props => (props.isFixed ? 0 : undefined)};
  z-index: ${props => (props.isFixed ? 99 : undefined)};
  width: 100%;
  max-width: 960px;
`;

export const MegaMenuItem = styled(TopicCard)`
  width: 150px;
  margin: 10px 16px 15px 16px;
  &.active {
    pointer-events: none;
    cursor: auto;
    ${Card} {
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
      &:after {
        content: "";
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 5px;
      }
    }
  }
  &:not(.active) {
    ${Card} {
      box-shadow: none;
    }
  }
`;
