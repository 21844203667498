import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import uuidv4 from "uuid/v4";

const getName = (obj?: any, language?: string, defaultLanguage?: string) => {
  const name = (find(obj, { language }) || {}).name;
  if (!name) {
    return (find(obj, { language: defaultLanguage }) || {}).name;
  } else {
    return name;
  }
};

const getLanguageData = (
  obj?: any,
  language?: string,
  defaultLanguage?: string
) => {
  const data = find(obj, { language }) || {};
  if (isEmpty(data)) {
    return find(obj, { language: defaultLanguage }) || {};
  } else {
    return data;
  }
};

const getUdid = () => {
  let udid = window.localStorage.getItem("udid");
  if (!udid) {
    udid = uuidv4();
    window.localStorage.setItem("udid", udid);
  }
  return udid;
};

export default {
  AlertBox: (message: string) => {},
  getName,
  getLanguageData,
  getUdid,
};
