import idx from "idx";
import compact from "lodash/compact";
import find from "lodash/find";
import { LocationType } from "src/store/types";
import { AreaType } from "src/store/types/areas";

export const handleLocationResponse = (location: LocationType) => {
  const areas = idx(location, x => x.areas) || ([] as AreaType[]);
  // Sort Areas based on AreaOrder
  const areaOrder = idx(location, x => x.areaOrder) || [];

  let orderedAreas = areaOrder.map(areaId => {
    return find(areas, { _id: { $oid: areaId } });
  });
  if (areaOrder.length <= 0) {
    orderedAreas = areas;
  }
  orderedAreas = compact(orderedAreas);

  const updatedLocation = {
    ...location,
    areas: orderedAreas,
  };

  // Iterate through all areas and sort exhibit if required and update areas
  let updatedAreasData = {};
  for (let index = 0; index < orderedAreas.length; index++) {
    const areaData = orderedAreas[index];
    const areaId = idx(areaData, x => x._id.$oid);
    updatedAreasData = {
      ...updatedAreasData,
      [areaId]: { data: areaData },
    };
  }

  return {
    location: updatedLocation,
    areas: updatedAreasData,
  };
};
