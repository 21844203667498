import idx from "idx";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Spinner from "src/components/Spinner";
import * as AppActions from "src/store/actions/app";
import * as ExhibitActions from "src/store/actions/exhibit";
import * as LocationActions from "src/store/actions/locations";
import { IReduxState } from "src/store/reducers";
import { ExhibitDataType, LocationType } from "src/store/types";
import styled from "styled-components";

type Props = IScreenProps & {
  exhibitId?: string;
  exhibitData?: ExhibitDataType;
  locationId?: string;
  locations?: LocationType;
  currentExhibitViewsBy?: string;
  getExhibit?: Function;
  getLocation?: Function;
  setCurrentExhibitId?: Function;
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 35px;

  margin-top: 60px;
  margin-bottom: 32px;
  max-width: 440px;
  max-height: 320px;

  /* Neutral/neutral-2 */
  background: #fafafa;

  /* Neutral/neutral-3 */
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 30px;
`;

const CardContent = styled.p`
  /* Regular / 20px | 28px */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  /* or 140% */

  /* Neutral/neutral-6 */
  color: #000000;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 10px 0px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: white;
`;

const BranchSMS: React.FC<Props> = props => {
  const {
    exhibitId,
    locationId,
    getLocation,
    setCurrentExhibitId,
    currentExhibitViewsBy,
  } = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.branch.data((error, response) => {
      // @ts-ignore
      setCurrentExhibitId(
        response.data_parsed.exhibit_id,
        currentExhibitViewsBy
      );
      setLoading(false);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (locationId) {
      getLocation(locationId);
    }
  }, [exhibitId, locationId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (!exhibitId) {
    return (
      <Container>
        <Card>
          <CardContent>
            Please re-use your Liiingo Link to download this app
          </CardContent>
        </Card>
      </Container>
    );
  }

  return <Redirect to={`/topic/${exhibitId}`} />;
};

export default connect<Props>(
  (state: IReduxState, ownProps: Props) => {
    const exhibitId = idx(state, x => x.app.currentExhibitId);
    const exhibitDetails = idx(state, x => x.topics.exhibitDetails[exhibitId]);
    const exhibit = idx(exhibitDetails, x => x.data) || ({} as ExhibitDataType);
    const locationId = idx(exhibit, x => x.locationId);
    const locations = idx(state, x => x.location.locations[locationId].data);

    return {
      exhibitId,
      locationId,
      locations,
      currentExhibitViewsBy: idx(state, x => x.app.currentExhibitViewsBy),
    };
  },
  {
    getExhibit: ExhibitActions.getExhibit,
    setCurrentExhibitId: AppActions.setCurrentExhibitId,
    getLocation: LocationActions.getLocation,
  }
)(BranchSMS);
