import * as React from "react";
import { Dot, DotInner, DotWrapper } from "./styled";

export interface Props {
  total: number;
  current: number;
  dotColor?: any;
  activeColor?: any;
}

function getClassName(total, current, index) {
  const classNames = ["large"];
  if (current - index === 0) {
    classNames.push("active");
  }
  return classNames.join(" ");
}

const NavigationDots: React.FC<Props> = props => {
  return (
    <DotWrapper>
      <DotInner current={props.current} total={props.total}>
        {[...Array(props.total)].map((e, i) => (
          <Dot
            key={i}
            dot-color={props.dotColor}
            active-color={props.activeColor}
            className={getClassName(props.total, props.current, i)}
          />
        ))}
      </DotInner>
    </DotWrapper>
  );
};

export default NavigationDots;
