import { Pannellum } from "pannellum-react";
import * as React from "react";
import { Maximize, Minimize } from "react-feather";
import { Portal } from "react-portal";
import {
  Image360Full,
  Image360FullScreenButton,
  Image360Wrapper,
} from "./styled";

interface Props {
  url: string;
}

const Image360: React.FC<Props> = ({ url }) => {
  const [isFullScreen, setFullScreen] = React.useState(false);

  const Panorama = (
    <Pannellum
      width="100%"
      height={isFullScreen ? "100%" : "200px"}
      image={url}
      pitch={0}
      hfov={5000} // max zoom out
      autoLoad
      onMousedown={e => e.stopPropagation()}
      onMouseup={e => e.stopPropagation()}
      onTouchstart={e => e.stopPropagation()}
      onTouchend={e => e.stopPropagation()}
      showFullscreenCtrl={false}
      showControls={false}
    />
  );

  const FullScreenButton = (
    <Image360FullScreenButton
      onClick={() => {
        setFullScreen(val => !val);
      }}
      className="fullscreen-btn"
    >
      {!isFullScreen ? <Maximize /> : <Minimize />}
    </Image360FullScreenButton>
  );

  const renderPanorama = () => {
    return (
      <>
        {Panorama}
        {FullScreenButton}
      </>
    );
  };

  return (
    <Image360Wrapper className="content-slide" id="panorama">
      {renderPanorama()}
      {isFullScreen && (
        <Portal>
          <Image360Full>{renderPanorama()}</Image360Full>
        </Portal>
      )}
    </Image360Wrapper>
  );
};

export default Image360;
