import { Link as BaseLink } from "react-router-dom";
import styled from "styled-components";

export interface Props {
  image?: string;
  title: string;
  className?: string;
  exhibitId: string;
  onClick?: () => void;
}

export const Link = styled(BaseLink)`
  text-decoration: none;
`;

export const Card = styled.div<{ image: string }>`
  width: 150px;
  height: 150px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background-position: center center;
  background-size: cover;
  position: relative;
  background-image: ${props => "url(" + props.image + ")"};
`;

export const Title = styled.div`
  background-color: white;
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  border-radius: 5px;
  padding: 10px;
  color: ${props => props.theme.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
