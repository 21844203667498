import Types from "../types/app";
import ExploredTypes from "../types/explored";
import LocationTypes from "../types/locations";
export const dummyReduxEvent = () => {
  return {
    type: Types.DUMMY_REDUX_EVENT,
  };
};

export const setIsConnectedToInternet = (isConnected: boolean) => {
  return {
    type: Types.SET_IS_CONNECTED_TO_INTERNET,
    payload: isConnected,
  };
};

export const addVisitedScreen = (
  screenName: string,
  params: any
) => dispatch => {
  dispatch({
    type: Types.ADD_VISITED_SCREENS,
    payload: {
      screenName,
      params,
    },
  });
};

export const removeLastVisitedScreen = () => {
  return {
    type: Types.REMOVE_VISITED_SCREENS,
  };
};

export const resetVisitedScreen = () => {
  return {
    type: Types.RESET_VISITED_SCREEN,
  };
};

export const removeLocation = (locationId: string) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: ExploredTypes.REMOVE_EXHIBIT,
    payload: {
      locationId,
    },
  });
  dispatch({
    type: LocationTypes.REMOVE_LOCATION,
    payload: {
      locationId,
    },
  });
};

export const setCurrentExhibitId = (id, viewsBy) => {
  return {
    type: Types.SET_CURRENT_EXHIBIT_ID,
    payload: { id, viewsBy },
  };
};

export const setCurrentSectionId = id => {
  return {
    type: Types.SET_CURRENT_SECTION_ID,
    payload: id,
  };
};

export const setCurrentMenuId = id => {
  return {
    type: Types.SET_CURRENT_MENU_ID,
    payload: id,
  };
};
