import TopicCard from "src/components/TopicCard";
import styled from "styled-components";

export const Container = styled.div``;

export const TopicList = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center;
  flex-wrap: wrap !important;
  padding-bottom: 20px;
  min-height: calc(100vh - 250px);
  align-content: baseline;
`;

export const TopicWrapper = styled(TopicCard)`
  margin: 18px 10px 18px 10px;
`;
