import React, { useEffect, useState } from "react";
import { ImQrcode } from "react-icons/im";
import { IoMdClose } from "react-icons/io";
import { createPortal } from "react-dom";
import styled from "styled-components";
import QRCodeScreen from "../QRCodeScreen";
import { ShareNotification } from "../ShareButton/styled";

// Modal content component
const ModalContent = styled.div`
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  min-height: 280px;
  min-width: 280px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
`;

// Modal backdrop component
const ModalBackdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const CloseButton = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #888;

  &:hover {
    color: #333;
  }
`;

const FABWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d4dffd;
  z-index: 999;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  &:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: scale(0.95);
  }

  @media only screen and (min-width: 768px) {
    right: calc(25%);
  }
`;

export const RotateOnHover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: rotate(45deg);
  }
`;

//FloatingActionButton
const FAB = ({ organizationName, pageName, qrImage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notification, setNotification] = useState("");
  const [shareBtn, setShareBtn] = useState(false);
  const shareNow = () => {
    try {
      const shareData = {
        title: "Share the Joy: " + organizationName,
        text: pageName,
        url: window.location.href,
      };
      if (navigator.share) {
        if (navigator.canShare) {
          navigator
            .share(shareData)
            .then(() => {
              setNotification("Content shared successfully!");
            })
            .catch(error => {
              setNotification(
                "An error occurred while sharing. Please try again."
              );
            });
        } else {
          setNotification("This content cannot be shared on this device.");
        }
      } else {
        setNotification("Sharing is not supported on this device.");
      }
    } catch (error) {
      setNotification("This device cannot share!");
    }
    setShareBtn(!shareBtn);
  };
  const renderNotification = () => {
    if (notification) {
      return <ShareNotification>{notification}</ShareNotification>;
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setTimeout(() => setShareBtn(false), 5000);
  }, [shareBtn]);

  return (
    <>
      <FABWrapper>
        <RotateOnHover>
          <ImQrcode onClick={toggleModal} style={{ width: 30, height: 30 }} />
        </RotateOnHover>
      </FABWrapper>
      {isOpen &&
        createPortal(
          <div>
            <ModalBackdrop>
              <ModalContent>
                <CloseButton onClick={toggleModal}>
                  <RotateOnHover>
                    <IoMdClose />
                  </RotateOnHover>
                </CloseButton>
                <QRCodeScreen
                  organizationName={organizationName}
                  pageName={pageName}
                  image={qrImage}
                  onShareButton={() => shareNow()}
                />
              </ModalContent>
            </ModalBackdrop>
            {shareBtn && renderNotification()}
          </div>,
          document.body
        )}
    </>
  );
};

export default FAB;
