import idx from "idx";
import * as React from "react";
import { connect } from "react-redux";
import defaultImage from "src/assets/images/default-header.png";
import { IReduxState } from "src/store/reducers";
import { LocationType } from "src/store/types";
import LanguageSelect from "../LanguageSelect";

import {
  BannerTopIconWrapper,
  BannerWrapper,
  Container,
  Overlay,
  Props,
} from "./styled";
interface InternalProps {
  location?: LocationType;
  children?: React.ReactNode;
  image?: string;
  useImageFromLocation?: boolean;
}

const Banner: React.FC<Props & InternalProps> = props => {
  const { location, children, locationId, image, useImageFromLocation } = props;

  let bannerImage = image;
  if (useImageFromLocation) {
    bannerImage =
      idx(location, x => x.backgroundImageThumb) ||
      idx(location, x => x.backgroundImage);
  }

  return (
    <BannerWrapper image={bannerImage || defaultImage}>
      <Container>
        <BannerTopIconWrapper>
          <LanguageSelect locationId={locationId} />
        </BannerTopIconWrapper>
      </Container>
      {children}
      {/* show gradient overlay if there is custom image */}
      {bannerImage && <Overlay />}
    </BannerWrapper>
  );
};

Banner.defaultProps = {
  useImageFromLocation: true,
};

export default connect<{}, {}, Props, {}>(
  (state: IReduxState, ownProps: Props) => {
    const id = ownProps.locationId;
    const location =
      idx(state, x => x.location.locations[id].data) || ({} as LocationType);
    return {
      location,
    };
  }
)(Banner);
