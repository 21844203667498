import { MdShare } from "react-icons/md";
import { device } from "src/helpers/responsive";
import styled from "styled-components";

export const Share = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  @media ${device.tabletSm} {
    position: relative;
    margin-left: auto;
  }
`;

export const ShareNotification = styled.span`
  background: ${props => props.theme.tabBackgroundColor};
  border-radius: 8px;
  box-shadow: -3px 3px 10px #e5e5e6bc, 3px -3px 10px #ffffff9f;
  width: fit-content;
  padding: 8px;
  font-size: 12px;
  min-width: 80px;
  color: gray;
  position: absolute;
  top: 2rem;
  right: 0.8rem;
  z-index: 9999;
  text-align: center;
  @media ${device.tabletSm} {
    top: 2.5rem;
    right: 0rem;
  }
`;

export const ShareIcon = styled(MdShare)`
  color: ${props => props.theme.primary};
  font-size: clamp(1.5rem, 0.9643rem + 2.6786vw, 2.25rem);
  @media ${device.tabletSm} {
    line-height: 45px;
  }
`;
