import createTypes from "redux-create-action-types";

export interface VisitedExhibitType {
  timestamp: any;
  locationId: string;
  locationTitle: string;
  areaId: string;
  exhibitId: string;
}

export default createTypes("SET_VISITED_EXHIBIT", "REMOVE_EXHIBIT");
