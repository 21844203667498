import React from "react";
import styled from "styled-components";
import { ImShare } from "react-icons/im";
import { IoShareSocial } from "react-icons/io5";
import { IoShareOutline } from "react-icons/io5";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const OrganizationName = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 18px;
  color: #888;
`;

const PageName = styled.div`
  font-size: 12px;
  margin-bottom: 16px;
  color: #888;
`;

const QRCodeContainer = styled.div`
  border: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 16px;
`;

const QRCodeImage = styled.img`
  width: 250px;
  height: 250px;
`;

const PoweredBy = styled.div`
  font-size: 14px;
  color: #888;
`;

// It uses a series of regular expression tests (/regex/i.test(userAgent)) to check if the user agent string contains specific keywords related to different platforms
export const getDeviceIcon = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (/android/i.test(userAgent)) {
    return <IoShareSocial style={{ color: "#888", width: 40, height: 40 }} />;
  } else if (/iphone|ipod|ipad/i.test(userAgent)) {
    return <IoShareOutline style={{ color: "#888", width: 40, height: 40 }} />;
  } else if (/mac/i.test(userAgent)) {
    return <ImShare style={{ color: "#888", width: 40, height: 40 }} />;
  } else {
    return <IoShareSocial style={{ color: "#888", width: 40, height: 40 }} />;
  }
};
const QRCodeScreen = ({ organizationName, pageName, image, onShareButton }) => {
  return (
    <Container>
      <OrganizationName>{organizationName}</OrganizationName>
      <PageName>{pageName}</PageName>
      <QRCodeContainer>
        <QRCodeImage src={image} alt="QR Code" onClick={onShareButton} />
      </QRCodeContainer>
      <PoweredBy onClick={onShareButton}>{getDeviceIcon()}</PoweredBy>
    </Container>
  );
};

export default QRCodeScreen;
