import idx from "idx";
import Socket from "src/helpers/Socket";
import Constants from "src/helpers/constants";
import store from "src/store";
import { IReduxState } from "src/store/reducers";
import Request from "./request";

export const saveAnalyticsEvent = async (type: string, metadata: any = {}) => {
  const state: IReduxState = store.getState();
  const udid = idx(state, x => x.app.udid);

  // increase events count in socket metadata so we can determine bounce rate
  Socket.increaseEventsCount();

  return Request.call({
    url: "/user-analytic/save",
    method: "POST",
    data: {
      type,
      udid,
      os: Constants.os(),
      metadata: {
        ...metadata,
        platform: "LiiingoWebApp",
        browser: navigator.userAgent,
        appSession: Socket.socket.id, // send app session socket id with every request so we can come to know how many event has been done
      },
    },
  });
};

export enum ViewsByTypes {
  "link" = "link",
  "share" = "share",
  "scan" = "scan",
  "menu" = "menu",
}

export interface TopContent {
  exhibitId: string;
  areaId: string;
  locationId: string;
  organizationId: string;
  viewsBy?: ViewsByTypes;
}

export const captureTopContent = (data: TopContent) => {
  return saveAnalyticsEvent("top_content", data);
};

export interface ShareEvent {
  exhibitId: string;
  areaId: string;
  locationId: string;
  organizationId: string;
  channel: string;
}

export const captureShareEvent = (data: ShareEvent) => {
  return saveAnalyticsEvent("share", data);
};

export interface SetLanguageMetadata {
  locationId: string;
  supportedLanguages: string[];
  activeLanguage: string;
  deviceLanguage: string;
  requestType: "SET_LANGUAGE_FROM_DEVICE" | "SET_ACTIVE_LANGUAGE";
}

export const captureSetLanguage = requestType => {
  const state: IReduxState = store.getState();
  let locationId = state.app.currentMenuId;
  let location = state.location.locations[locationId]?.data;
  let supportedLanguages = location?.supportedLanguages;
  let activeLanguage = state.language.activeLanguage;
  let deviceLanguage = state.language.deviceLanguage;

  let metadata: SetLanguageMetadata = {
    locationId,
    supportedLanguages,
    activeLanguage,
    deviceLanguage,
    requestType,
  };

  return saveAnalyticsEvent("set_language", metadata);
};
