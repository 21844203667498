import idx from "idx";
import Types, { ExhibitDataType } from "../types/exhibit";

const initExhibitDetails = {} as {
  [key: string]: {
    data: ExhibitDataType;
    locationId: string;
    url: string;
    loading: boolean;
    lastFetchedTime: number;
  };
};

const initialState = {
  exhibitDetails: initExhibitDetails,
  whiteLabelling: {
    backgroundImage: "",
    logo: "",
    color: "",
  },
  currentExhibit: "",
  findingExhibitId: false,
  swipingExhibitLoading: {} as { [exhibitId: string]: boolean },
  secretExhibits: initExhibitDetails,
};

export default (
  state = initialState,
  action: IReduxAction
): typeof initialState => {
  switch (action.type) {
    case Types.SET_WHITE_LABELING: {
      return {
        ...state,
        whiteLabelling: action.payload.whiteLabelData,
      };
    }
    case Types.FINDING_EXHIBIT_ID: {
      return {
        ...state,
        findingExhibitId: action.payload || false,
      };
    }
    case Types.GET_EXHIBIT_SUCCESS: {
      const {
        id,
        data,
        locationId,
        isExhibitAlreadyCached,
        isSecret,
      } = action.payload;

      const secretExhibits = isSecret
        ? {
          ...state.secretExhibits,
          [id]: {
            loading: false,
            data,
            locationId,
            lastFetchedTime: Date.now(),
          },
        }
        : { ...state.secretExhibits };

      if (isExhibitAlreadyCached) {
        // if it is already cached then just sent loading to false
        return {
          ...state,
          currentExhibit: id,
          exhibitDetails: {
            ...state.exhibitDetails,
            [id]: {
              ...(idx(state, x => x.exhibitDetails[id]) || {}),
              loading: false,
            },
          },
          secretExhibits,
        };
      } else {
        return {
          ...state,
          currentExhibit: id,
          exhibitDetails: {
            ...state.exhibitDetails,
            [id]: {
              loading: false,
              data,
              locationId,
              lastFetchedTime: Date.now(),
            },
          },
          secretExhibits,
        };
      }
    }
    case Types.GET_EXHIBIT_REQUEST: {
      const { id } = action.payload;
      return {
        ...state,
        currentExhibit: id,
        exhibitDetails: {
          ...state.exhibitDetails,
          [id]: {
            ...(idx(state, x => x.exhibitDetails[id]) || {}),
            loading: true,
          },
        },
      };
    }
    case Types.GET_EXHIBIT_ERROR: {
      const { id, error } = action.payload;
      return {
        ...state,
        exhibitDetails: {
          ...state.exhibitDetails,
          [id]: {
            ...(idx(state, x => x.exhibitDetails[id]) || {}),
            loading: false,
            error,
          },
        },
      };
    }
    case Types.SWIPED_EXHIBIT_LOADING_REQUEST: {
      const exhibitId = action.payload;
      return {
        ...state,
        currentExhibit: exhibitId,
        swipingExhibitLoading: {
          ...state.swipingExhibitLoading,
          [exhibitId]: true,
        },
      };
    }
    case Types.SWIPED_EXHIBIT_LOADING_COMPLETE: {
      const exhibitId = action.payload;
      return {
        ...state,
        currentExhibit: exhibitId,
        swipingExhibitLoading: {
          ...state.swipingExhibitLoading,
          [exhibitId]: false,
        },
      };
    }
    case Types.UPDATE_CACHED_EXHIBIT_DATA: {
      const { id, data } = action.payload;
      return {
        ...state,
        exhibitDetails: {
          ...state.exhibitDetails,
          [id]: {
            ...(idx(state, x => x.exhibitDetails[id]) || {}),
            data,
            lastFetchedTime: Date.now(),
          },
        },
      };
    }
    default:
      return state;
  }
};
