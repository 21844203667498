import createTypes from "redux-create-action-types";

export default createTypes(
  "DUMMY_REDUX_EVENT",
  "SET_ACTIVE_LANGUAGE",
  "SET_LANGUAGE_FROM_DEVICE",
  "SET_IS_CONNECTED_TO_INTERNET",
  "ADD_VISITED_SCREENS",
  "REMOVE_VISITED_SCREENS",
  "RESET_VISITED_SCREEN",
  "UPDATE_PUSH_NOTIFICATION_SETTINGS_FOR_LOCATION",
  "SET_CURRENT_EXHIBIT_ID",
  "SET_CURRENT_SECTION_ID",
  "SET_CURRENT_MENU_ID",
  "SET_AUTOPLAY_VIDEO"
);
