/**
 * @name resposive params
 * @description Consist of responsive params with different screen sizes
 */

const size = {
  mobile: "320px",
  tabletSm: "600px",
  tabletLg: "768px",
  desktop: "960px",
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tabletSm: `(min-width: ${size.tabletSm})`,
  tabletSmAsMax: `(max-width: ${size.tabletSm})`,
  tabletLg: `(min-width: ${size.tabletLg})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopAsMax: `(max-width: ${size.desktop})`,
};
