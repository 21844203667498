import Types, { AreaType } from "../types/areas";
import LocationTypes from "../types/locations";

const initialState = {
  areas: {} as {
    [areaId: string]: {
      data: AreaType;
    };
  },
};

export default (
  state = initialState,
  action: IReduxAction
): typeof initialState => {
  switch (action.type) {
    case Types.UPDATE_MULTIPLE_AREAS: {
      const { data } = action.payload;
      return {
        ...state,
        areas: {
          ...state.areas,
          ...data,
        },
      };
    }
    case LocationTypes.UPDATE_LOCATION_AND_AREAS: {
      const { areas = {} } = action.payload;
      return {
        ...state,
        areas: {
          ...state.areas,
          ...areas,
        },
      };
    }
    default:
      return state;
  }
};
