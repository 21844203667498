import styled from "styled-components";

export default styled.div`
  max-width: 960px;
  width: 100%;
  margin: 0px auto;
  margin-bottom: 30px;
  background-color: white;
  min-height: 100vh;
`;
