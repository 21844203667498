import { AxiosInstance, AxiosRequestConfig, default as BaseAxios } from "axios";

class Request {
  public axios: AxiosInstance;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 10000 });
  }

  public async call(config: AxiosRequestConfig) {
    try {
      const serverBaseUrl = process.env.REACT_APP_API_URL;
      const headers: any = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      const res = await this.axios.request({
        baseURL: serverBaseUrl,
        headers,
        ...config,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new Request();
