import createTypes from "redux-create-action-types";
import { AreaType } from "./areas";

export interface LocationType {
  areas: AreaType[];
  areaOrder: string[];
  name: string;
  companyName: string;
  defaultLanguage: string;
  status: number;
  supportedLanguages: string[];

  headerLogoThumb: string;
  backgroundImageThumb: string;
  mobileBackgroundThumb: string;
  mobileLogoThumb: string;

  headerLogo: string;
  backgroundImage: string;
  mobileBackground: string;
  mobileLogo: string;
  mobileTextColor: string;
  organizationId: string;
  _id: { $oid: string };
}

export default createTypes(
  "UPDATE_LOCATION",
  "UPDATE_LOCATION_AND_AREAS",
  "REMOVE_LOCATION",

  "GET_LOCATION_REQUEST",
  "GET_LOCATION_COMPLETE",
  "GET_LOCATION_FAILURE",

  "UPDATE_EXHIBITS_IN_LOCATION",
  "UPDATE_LOCATION_WITHOUT_TEMPORARY_PAGE"
);
