import { groupBy, uniqBy } from "lodash";
import Types, { VisitedExhibitType } from "../types/explored";

const initialState = {
  visitedExhibits: [] as VisitedExhibitType[],
  visitedExhibitsByLocation: {} as {
    [locationId: string]: VisitedExhibitType[];
  },
};

export default (
  state = initialState,
  action: IReduxAction
): typeof initialState => {
  switch (action.type) {
    case Types.SET_VISITED_EXHIBIT: {
      const timestamp = new Date();
      const { locationId, locationTitle, areaId, exhibitId } = action.payload;
      let exhibits = [
        ...state.visitedExhibits,
        { timestamp, locationId, locationTitle, areaId, exhibitId },
      ];
      exhibits = uniqBy(exhibits, "exhibitId");

      return {
        ...state,
        visitedExhibits: exhibits,
        visitedExhibitsByLocation: groupBy(exhibits, "locationId"),
      };
    }
    case Types.REMOVE_EXHIBIT: {
      let visitedExhibitsNew = [];
      visitedExhibitsNew = state.visitedExhibits.filter(
        location => location.locationId !== action.payload.locationId
      );
      return {
        ...state,
        visitedExhibits: visitedExhibitsNew,
        visitedExhibitsByLocation: groupBy(visitedExhibitsNew, "locationId"),
      };
    }
    default:
      return state;
  }
};
