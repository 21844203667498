import idx from "idx";
import * as Api from "src/api";
import { handleLocationResponse } from "src/helpers/location";
import Types from "../types/locations";

export const getArea = id => async dispatch => {
  try {
    const res = await Api.getArea(id);
    const { location, areas } = handleLocationResponse(res.location);
    dispatch({
      type: Types.UPDATE_LOCATION_AND_AREAS,
      payload: {
        location: {
          id: idx(location, x => x._id.$oid),
          data: location,
        },
        areas,
      },
    });
  } catch (error) {
    // console.log(error);
  }
};
