import styled from "styled-components";

export interface Props {
  locationId: string;
}

// TODO: REMOVE COMMENT ONCE CHANGES FOR FLAG GOT REMOVED

// export const LanguageButton = styled.div<{
//   flag: string;
//   variant?: "lg" | "sm";
// }>`
//   height: ${props => (props.variant === "lg" ? "200px" : "40px")};
//   width: ${props => (props.variant === "lg" ? "200px" : "40px")};
//   border-radius: 50%;
//   background-position: center center;
//   background-size: cover;
//   position: relative;
//   background-image: ${props => "url(" + props.flag + ")"};
//   cursor: pointer;
// `;
/* eslint-disable no-unexpected-multiline */
export const LanguageButton = styled.div<{
  variant?: "lg" | "sm";
}>`
  height: ${props => (props.variant === "lg" ? "140px" : "40px")};
  width: ${props => (props.variant === "lg" ? "140px" : "40px")};
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.94);
  text-transform: uppercase;
  border: ${props =>
    props.variant === "lg"
      ? "8px solid rgba(181, 181, 181, 0.97)"
      : "4px solid rgba(181, 181, 181, 0.97)"};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
`;
/* eslint-disable no-unexpected-multiline */

export const LanguageCodeText = styled.span<{
  variant?: "lg" | "sm" | "xs";
}>`
  font-size: ${props =>
    props.variant === "lg" ? "30px" : props.variant === "xs" ? "11px" : "15px"};
  font-weight: 800;
  color: rgba(100, 100, 99, 1);
`;

export const LanguageTitle = styled.div<{ variant?: "lg" | "sm" }>`
  color: #ffffff;
  font-size: ${props => (props.variant === "lg" ? "30px" : "15px")};
  padding-top: 10px;
`;

export const LanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 25px 0px 25px 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  z-index: 99;
`;

export const ModalTitle = styled.div<{ variant?: "lg" | "sm" }>`
  padding-top: 25px;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: ${props => (props.variant === "lg" ? "45px" : "25px")};
`;

export const CloseButton = styled.div`
  cursor: pointer;
  color: white;
  font-size: 20px;
  position: absolute;
  right: 0px;
  z-index: 1;
  padding: 20px;
`;

export const LanguageCode = styled.h1`
  font-weight: 400;
  font-size: clamp(0.97rem, 0.8427rem + 0.6788vw, 1.25rem);
  line-height: 36px;
  text-align: center;
  color: white;
  margin: 0;
`;

export const LanguageSelectWrapper = styled.div`
  display: "flex";
  flex-direction: "column";
`;
