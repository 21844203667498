/**
 * @name Tab Component
 * @description Custom Tab component using swiper
 */

import idx from "idx";
import find from "lodash/find";
import React, { FC, useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import theme from "src/helpers/theme";
import utility from "src/helpers/utility";
import { IReduxState } from "src/store/reducers";
import { ExhibitDataType, LocationType } from "src/store/types";
import { AreaType } from "src/store/types/areas";
import {
  MainTabMenuWrapper,
  MegaMenu,
  MegaMenuInnerContainer,
  MegaMenuItem,
  SwiperOutWrapper,
  Tab,
  TabArrowWrapper,
  TabWrapper,
} from "./styled";

interface TabType {
  label: string;
  key: string;
  index?: number;
  exhibitsLength?: number;
}

interface Props {
  index: number;
  onChange?: Function;
  items: TabType[];
  onTabClick?: (tab: TabType) => void;
  isDropDownDisabled?: boolean;
  activeLanguage: string;
  exhibitId?: string;
  locationId?: string;
}

interface InternalProps {
  exhibit?: ExhibitDataType;
  location?: LocationType;
}
const TIMEOUT = 500;

const Tabs: FC<Props & InternalProps> = props => {
  const {
    index,
    onChange,
    items,
    onTabClick,
    isDropDownDisabled,
    exhibitId,
    exhibit,
    location,
    activeLanguage,
  } = props;
  const [internalIndex, setInternalIndex] = useState(props.index || 0);
  const [sliderRef, setSliderRef] = useState(null);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState(false);
  let flagPoint = 0;
  const areaId = idx(exhibit, x => x.areaId);
  const areas = idx(location, x => x.areas) || [];
  const area: AreaType =
    find(areas, { _id: { $oid: areaId } }) || ({} as AreaType);
  const exhibits: ExhibitDataType[] = idx(area, x => x.exhibits) || [];
  const defaultLanguage = location.defaultLanguage;
  const [isEnd, setIsEnd] = useState<boolean>();
  const [isBegining, setIsBegining] = useState<boolean>();

  // Displays the correct tab when the selected index changes
  useEffect(() => {
    if (sliderRef && sliderRef.slides[index]) {
      sliderRef.slideTo(index, 300, false);
      updateStartEndOfTab();
    }
  }, [sliderRef, index]);

  // on index change, change slider tab index
  useEffect(() => {
    const goToSlide = (ind: number) => {
      if (sliderRef) {
        sliderRef.slideTo(ind);
      }
    };
    if (internalIndex !== index) {
      goToSlide(index);
    }

    updateStartEndOfTab();
  }, [index, internalIndex, sliderRef, isEnd, isBegining]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeHandler = (currentSlide, nextSlide) => {
    setInternalIndex(nextSlide);
    if (onChange) {
      onChange(currentSlide, nextSlide);
    }
  };

  const updateStartEndOfTab = () => {
    if (sliderRef) {
      setIsBegining(sliderRef.isBeginning);
      setIsEnd(sliderRef.isEnd);
    }
  };

  const scrollToContentBegining = () => {
    return isFixed && window.scroll({ top: 250, left: 0, behavior: "smooth" });
  };

  const goNext = () => {
    if (sliderRef !== null) {
      sliderRef.slideNext();
      updateStartEndOfTab();
    }
  };

  const goPrev = () => {
    if (sliderRef !== null) {
      sliderRef.slidePrev();
      updateStartEndOfTab();
    }
  };

  const params = {
    slidesPerView: "auto",
    freeMode: true,
    freeModeSticky: true,
    freeModeMomentumRatio: 0.25,
    freeModeMomentumVelocityRatio: 0.5,
    slideToClickedSlide: true,
    on: {
      slideChange: () => {
        if (onChange) {
          onChange(sliderRef.activeIndex);
        }
        updateStartEndOfTab();
      },
    },
  };

  let timer;

  const mouseEnter = () => {
    timer = setTimeout(() => {
      setShowMenu(true);
    }, TIMEOUT);
  };

  const mouseLeave = () => {
    setShowMenu(false);
    clearTimeout(timer);
  };

  document.addEventListener("scroll", () => {
    const tab = document.getElementById("tabline");
    const space = tab && tab.offsetTop;
    if (window.scrollY > space && window.scrollY > flagPoint) {
      setIsFixed(true);
    } else if (window.scrollY < flagPoint) {
      setIsFixed(false);
    } else {
      flagPoint = space > 0 ? space : flagPoint;
      setIsFixed(false);
    }
  });

  return (
    <MainTabMenuWrapper
      id="tabline"
      isFixed={isFixed}
      onMouseLeave={() => mouseLeave()}
    >
      <TabWrapper>
        <TabArrowWrapper>
          {!isBegining && (
            <AiOutlineArrowLeft
              color={theme.primary}
              size={21}
              onClick={() => goPrev()}
            />
          )}
        </TabArrowWrapper>

        <SwiperOutWrapper>
          <Swiper {...params} getSwiper={setSliderRef}>
            {items.map((tab, tabIndex) => (
              <Tab
                onMouseEnter={() => mouseEnter()}
                isSelected={index === tabIndex}
                key={tabIndex}
                data-index={tab.key}
                onClick={() => {
                  changeHandler("", tabIndex);
                  if (onTabClick) {
                    onTabClick(tab);
                  }
                  scrollToContentBegining();
                }}
                style={{ display: tab.exhibitsLength ? "block" : "none" }}
              >
                {tab.label}
              </Tab>
            ))}
          </Swiper>
        </SwiperOutWrapper>

        <TabArrowWrapper>
          {!isEnd && (
            <AiOutlineArrowRight
              color={theme.primary}
              size={21}
              onClick={() => goNext()}
            />
          )}
        </TabArrowWrapper>
      </TabWrapper>

      {showMenu && !isDropDownDisabled && (
        <MegaMenu>
          <MegaMenuInnerContainer>
            {exhibits.map((item, itemIndex) => {
              const names = idx(item, x => x.name);
              const exhibitName = utility.getName(
                names,
                activeLanguage,
                defaultLanguage
              );
              return (
                <MegaMenuItem
                  onClick={() => scrollToContentBegining()}
                  className={item._id.$oid === exhibitId ? "active" : ""}
                  image={item.exhibitImageThumb || item.exhibitImage}
                  key={itemIndex}
                  title={exhibitName}
                  exhibitId={item._id.$oid}
                />
              );
            })}
          </MegaMenuInnerContainer>
        </MegaMenu>
      )}
    </MainTabMenuWrapper>
  );
};

export default connect<{}, {}, Props, {}>((state: IReduxState, ownProps) => {
  const exhibit =
    idx(state, x => x.topics.exhibitDetails[ownProps.exhibitId].data) ||
    ({} as ExhibitDataType);
  const location =
    idx(state, x => x.location.locations[ownProps.locationId].data) ||
    ({} as LocationType);
  return {
    exhibit,
    location,
  };
})(Tabs);
